export const LIRE_FORMULAIRE = 'LIRE_FORMULAIRE';
export const CREER_FORMULAIRE = 'CREER_FORMULAIRE';
export const MODIFIER_FORMULAIRE = 'MODIFIER_FORMULAIRE';
export const LISTER_FORMULAIRES = 'LISTER_FORMULAIRES';
export const VALIDER_FORMULAIRES = 'VALIDER_FORMULAIRES';
export const APPROUVER_FORMULAIRES = 'APPROUVER_FORMULAIRES';
export const DIFFUSER_FORMULAIRES = 'DIFFUSER_FORMULAIRES';
export const SOUMETTRE_FORMULAIRES = 'SOUMETTRE_FORMULAIRES';
export const RENVOYER_FORMULAIRES = 'RENVOYER_FORMULAIRES';
export const CHANGER_ETAT_FORMULAIRES = 'CHANGER_ETAT_FORMULAIRES';
export const LISTER_FORMULAIRES_STRUCTURES = 'LISTER_FORMULAIRES_STRUCTURES';
export const CHANGER_FORMULAIRE = 'CHANGER_FORMULAIRE';

export const LIST_MOIS = [
  { code: 'JANUARY', value: 'Janvier' },
  { code: 'FEBRUARY', value: 'Février' },
  { code: 'MARCH', value: 'Mars' },
  { code: 'APRIL', value: 'Avril' },
  { code: 'MAY', value: 'Mai' },
  { code: 'JUNE', value: 'Juin' },
  { code: 'JULY', value: 'Juillet' },
  { code: 'AUGUST', value: 'Août' },
  { code: 'SEPTEMBER', value: 'Septembre' },
  { code: 'OCTOBER', value: 'Octobre' },
  { code: 'NOVEMBER', value: 'Novembre' },
  { code: 'DECEMBER', value: 'Décembre' }
];
