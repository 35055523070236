import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import ApiReducer from './slices/Api/Api.reducer';
import AuthReducer from './slices/Auth/Auth.reducer';
import DashboardReducer from './slices/Dashboard/Dashboard.reducer';
import DocumentsReducer from './slices/Documents/Documents.reducer';
import SaisieReducer from './slices/Saisies/Saisies.reducer';
import AnnuaireReducer from './slices/Annuaire/Annuaire.reducer';
import MailReducer from './slices/Mail/Mail.reducer';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  api: ApiReducer,
  auth: AuthReducer,
  dashboard: DashboardReducer,
  documents: DocumentsReducer,
  saisies: SaisieReducer,
  annuaire: AnnuaireReducer,
  mail: MailReducer
});

export { rootPersistConfig, rootReducer };
