import { UPLAOD_LOGO } from '../Documents/Documents.constants';
import {
  LOGIN_USER,
  LOGOUT_USER,
  LISTER_UTILISATEURS,
  LISTER_STRUCTURES,
  MODIFIER_STRUCTURES,
  CREER_UTILISATEUR,
  MODIFIER_UTILISATEUR,
  LIRE_CODIFICATION,
  BLOCAGE_STRUCTURES,
  RATTACHER_STRUCTURES,
  ENREGISTRER_CATEGORIE,
  INSCRIRE_STRUCTURE
} from './Auth.constants';

const emptyState = {
  identifiant: null,
  nomAffichable: null,
  roles: null,
  state: null,
  token: null,
  refreshToken: null,
  identifiantsGeneres: null,
  codification: [],
  utilisateurs: undefined,
  structures: undefined,
  autreStructure: undefined
};

let initialState = emptyState;
try {
  const cache = JSON.parse(localStorage.getItem('auth'));

  if (cache) {
    initialState = { ...cache };
  }
} catch (error) {
  console.log(error);
}

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_USER:
      localStorage.removeItem('auth');

      return {
        ...emptyState
      };

    case LOGIN_USER: {
      const auth = {
        identifiant: action.identifiant,
        roles: action.roles,
        nomAffichable: action.nomAffichable,
        token: action.token,
        refreshToken: action.refreshToken,
        dateExpirationJeton: action.dateExpirationJeton,
        forceChangePassword: action.forceChangePassword,
        dirty: false,
        urlLogo: action.urlLogo,
        autreStructure: action.autreStructure,
        structure: action.structure
      };
      localStorage.setItem('auth', JSON.stringify(auth));

      return {
        ...state,
        ...auth
      };
    }

    case UPLAOD_LOGO:
      return {
        ...state,
        urlLogo: action.data.url
      };
    case LISTER_UTILISATEURS:
    case CREER_UTILISATEUR:
    case MODIFIER_UTILISATEUR:
      return {
        ...state,
        utilisateurs: action.data
      };
    case LISTER_STRUCTURES:
    case INSCRIRE_STRUCTURE:
    case MODIFIER_STRUCTURES:
    case BLOCAGE_STRUCTURES:
    case RATTACHER_STRUCTURES:
      return {
        ...state,
        structures: action.data
      };
    case ENREGISTRER_CATEGORIE:
      return {
        ...state,
        codification: { ...state.codification, CATEGORIE_ARTICLE: undefined }
      };
    case LIRE_CODIFICATION:
      return {
        ...state,
        codification: { ...state.codification, [action.code]: action.data }
      };
    default:
      return state;
  }
};

AuthReducer.hydrate = () => initialState;

export default AuthReducer;
