import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Login from '../pages/authentication/Login';
import { isTokenValid } from '../redux/slices/Auth/Auth.selectors';

// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
  const user = useSelector((state) => state.auth);
  const tokenValid = useSelector((state) => isTokenValid(state));
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  function isAuthenticated() {
    return user && tokenValid;
  }

  if (!isAuthenticated()) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node
};
