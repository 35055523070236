export const LOGIN_USER = 'auth/LOGIN_USER';
export const LOGOUT_USER = 'auth/LOGOUT_USER';
export const LISTER_UTILISATEURS = 'utilisateurs/LISTER_UTILISATEURS';
export const LISTER_STRUCTURES = 'utilisateurs/LISTER_STRUCTURES';
export const CREER_STRUCTURES = 'utilisateurs/CREER_STRUCTURES';
export const RATTACHER_STRUCTURES = 'utilisateurs/RATTACHER_STRUCTURES';
export const MODIFIER_STRUCTURES = 'utilisateurs/MODIFIER_STRUCTURES';
export const MODIFIER_UTILISATEUR = 'utilisateurs/MODIFIER_UTILISATEUR';
export const BLOCAGE_STRUCTURES = 'utilisateurs/BLOCAGE_STRUCTURES';
export const CREER_UTILISATEUR = 'utilisateurs/CREER_UTILISATEUR';
export const LIRE_CODIFICATION = 'codification/LIRE_CODIFICATION';
export const ENREGISTRER_CATEGORIE = 'codification/ENREGISTRER_CATEGORIE';
export const INSCRIRE_STRUCTURE = 'structure/INCRIRE';
