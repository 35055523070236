import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// routes
import { useSelector } from 'react-redux';
import { PATH_DASHBOARD } from '../routes/paths';
import { isTokenValid } from '../redux/slices/Auth/Auth.selectors';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default function GuestGuard({ children }) {
  const user = useSelector((state) => state.auth);
  const tokenValid = useSelector((state) => isTokenValid(state));

  function isAuthenticated() {
    return user && tokenValid;
  }

  if (isAuthenticated()) {
    return <Navigate to={PATH_DASHBOARD.general.app} />;
  }

  return <>{children}</>;
}
