import { LOGOUT_USER } from '../Auth/Auth.constants';
import { LISTER_DOCUMENTS, UPLAOD_DOCUMENTS } from './Documents.constants';

function updateDocumentsState(oldData, docs, numero, type) {
  const newData = {
    ...oldData,
    ...{
      [numero]: oldData && oldData[numero] ? { ...oldData[numero], ...{ [type]: docs } } : { [type]: docs }
    }
  };
  return { list: newData };
}

function addDocumentsState(oldData, docs, numero) {
  const newData = {
    ...oldData,
    [numero]: docs
  };
  return { list: newData };
}

const initialState = {};

const DocumentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LISTER_DOCUMENTS:
      return {
        ...state,
        ...updateDocumentsState(state.list, action.data, action.numero, action.typeDocument)
      };
    case UPLAOD_DOCUMENTS:
      return {
        ...state,
        ...addDocumentsState(state.list, action.data, action.numero)
      };
    case LOGOUT_USER:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

DocumentsReducer.hydrate = () => initialState;

export default DocumentsReducer;
