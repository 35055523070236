import {
  UPDATE_DASHBOARD_DATAS,
  CLEAN_DASHBOARD_DATAS,
  LIRE_ECART_EFFECTIF,
  VERIFIER_RETARD_SAISIE
} from './Dashboard.constants';
import { LOGOUT_USER } from '../Auth/Auth.constants';
import {
  APPROUVER_FORMULAIRES,
  CHANGER_ETAT_FORMULAIRES,
  CREER_FORMULAIRE,
  DIFFUSER_FORMULAIRES,
  MODIFIER_FORMULAIRE,
  RENVOYER_FORMULAIRES,
  SOUMETTRE_FORMULAIRES,
  VALIDER_FORMULAIRES
} from '../Saisies/Saisies.constants';

const initialState = {};

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DASHBOARD_DATAS:
      return {
        ...state,
        data: action.data
      };
    case CREER_FORMULAIRE:
    case MODIFIER_FORMULAIRE:
    case SOUMETTRE_FORMULAIRES:
    case VALIDER_FORMULAIRES:
    case APPROUVER_FORMULAIRES:
    case DIFFUSER_FORMULAIRES:
    case RENVOYER_FORMULAIRES:
    case CHANGER_ETAT_FORMULAIRES:
    case CLEAN_DASHBOARD_DATAS:
      return {
        ...state,
        data: undefined
      };
    case VERIFIER_RETARD_SAISIE:
      return {
        ...state,
        retardSaisie: action.data
      };
    case LIRE_ECART_EFFECTIF:
      return {
        ...state,
        ecartEffectif: action.data
      };
    case LOGOUT_USER:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

DashboardReducer.hydrate = () => initialState;

export default DashboardReducer;
