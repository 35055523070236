import { push } from 'connected-react-router';
import {
  LOGIN_USER,
  LOGOUT_USER,
  LISTER_UTILISATEURS,
  LISTER_STRUCTURES,
  MODIFIER_STRUCTURES,
  CREER_UTILISATEUR,
  MODIFIER_UTILISATEUR,
  LIRE_CODIFICATION,
  BLOCAGE_STRUCTURES,
  RATTACHER_STRUCTURES,
  ENREGISTRER_CATEGORIE,
  INSCRIRE_STRUCTURE
} from './Auth.constants';
import { getRefreshToken, isTokenValid } from './Auth.selectors';
import { fetchApiAction } from '../../../setup/Api/api';
// import { LISTER_CLIENT } from '../Clients/Clients.constants';

function storeUserFromAPI(data) {
  return (dispatch) => {
    dispatch({
      type: LOGIN_USER,
      identifiant: data.identifiant,
      roles: data.roles,
      nomAffichable: data.nomAffichable,
      token: {
        value: data.token,
        expirationDate: data.dateExpirationJeton
      },
      refreshToken: data.refreshToken,
      forceChangePassword: data.forceChangePassword,
      urlLogo: data.urlLogo,
      autreStructure: data.autreStructure,
      structure: data.structure
    });
  };
}

export function authenticate() {
  return (dispatch, getState) => {
    const state = getState();

    if (!isTokenValid(state)) {
      return dispatch(refreshToken());
    }

    return false;
  };
}

export function refreshToken() {
  return (dispatch, getState) => {
    const token = getRefreshToken(getState());
    if (!token) {
      dispatch(push('/login'));
      return false;
    }

    return dispatch(
      fetchApiAction(
        'auth:refreshToken',
        { url: token },
        storeUserFromAPI,
        () => (dispatch) => dispatch(push('/login'))
      )
    );
  };
}

export function loginUser(user) {
  return fetchApiAction('auth:login', { body: user }, storeUserFromAPI);
}

export function mdpOublie(mail) {
  return fetchApiAction(
    'auth:mdpOublie',
    { url: mail },
    null,
    null,
    'Un nouveau mot de passe vous a été envoyé par mail'
  );
}
export function modifierMdp(body) {
  return fetchApiAction(
    'auth:modifierMdp',
    { body },
    storeUserFromAPI,
    null,
    'Votre mot de passe a été correctement modifié.'
  );
}

export function rattacherStructure(body) {
  return fetchApiAction(
    'utilisateurs:rattacherstructure',
    { body },
    (result) => ({
      type: RATTACHER_STRUCTURES,
      data: result
    }),
    null,
    'La structure a été correctement rattachée.'
  );
}

export function modifierStructure(body) {
  return fetchApiAction(
    'utilisateurs:modifierstructure',
    { body },
    (result) => ({
      type: MODIFIER_STRUCTURES,
      data: result
    }),
    null,
    'La structure a été correctement modifiée.'
  );
}

export function creerUtilisateur(body) {
  return fetchApiAction(
    'utilisateurs:creer',
    { body },
    (result) => ({
      type: CREER_UTILISATEUR,
      data: result
    }),
    null,
    "L'utilisateur a été correctement créé."
  );
}

export function modifierUtilisateur(body) {
  return fetchApiAction(
    'utilisateurs:modifier',
    { body },
    (result) => ({
      type: MODIFIER_UTILISATEUR,
      data: result
    }),
    null,
    "L'utilisateur a été correctement modifié."
  );
}

export function listerUtilisateurs() {
  return fetchApiAction('utilisateurs:lister', {}, (result) => ({
    type: LISTER_UTILISATEURS,
    data: result
  }));
}
export function listerStructures() {
  return fetchApiAction('utilisateurs:listerstructure', {}, (result) => ({
    type: LISTER_STRUCTURES,
    data: result
  }));
}

export function inscrireStructure(body) {
  return fetchApiAction(
    'utilisateur:inscrireStructure',
    { body },
    (result) => ({
      type: INSCRIRE_STRUCTURE,
      data: result
    }),
    null,
    "L'invitation a bien été envoyée à la structure"
  );
}

export function blocageStructure(blocage, id) {
  return fetchApiAction(blocage ? 'utilisateurs:bloquer' : 'utilisateurs:debloquer', { url: id }, (result) => ({
    type: BLOCAGE_STRUCTURES,
    data: result
  }));
}

export function lireCodification(code) {
  return fetchApiAction('codification:lister', { url: code }, (result) => ({
    type: LIRE_CODIFICATION,
    data: result,
    code
  }));
}

export function logoutUser() {
  return {
    type: LOGOUT_USER
  };
}

export function enregistrerCategorie(payload) {
  return fetchApiAction(
    'categorie:enregistrer',
    { body: payload },
    (result) => ({
      type: ENREGISTRER_CATEGORIE,
      data: result
    }),
    null,
    'La catégorie a bien été ajoutée.'
  );
}
