import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack5';
import { clearApiMessage } from '../setup/Api/Api.actions';
import { getErrorsMessages } from '../redux/slices/Api/Api.selectors';

export default function Toast() {
  const dispatch = useDispatch();
  const toasts = useSelector((state) => getErrorsMessages(state));
  const { enqueueSnackbar } = useSnackbar();

  const getStatusCode = (status) => (status ? 'success' : 'error');

  if (Object.keys(toasts).length === 0) {
    return null;
  }

  const toast = toasts[0];

  const msg = enqueueSnackbar(toast.message, { variant: getStatusCode(toast.success) });
  dispatch(clearApiMessage(toast.api));
  return msg;
}
