import { LOGOUT_USER } from '../Auth/Auth.constants';
import { LIRE_ANNUAIRE, ENREGISTRER_ANNUAIRE, GENERER_ANNUAIRE, CHANGE_DOWNLOAD } from './Annuaire.constants';

const initialState = {};

const AnnuaireReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIRE_ANNUAIRE:
    case ENREGISTRER_ANNUAIRE:
      return { ...state, annuaireSelectionne: action.data };
    case GENERER_ANNUAIRE:
      return { ...state, annuaireSelectionne: action.data, download: true };
    case CHANGE_DOWNLOAD:
      return { ...state, download: action.download };
    case LOGOUT_USER:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

AnnuaireReducer.hydrate = () => initialState;

export default AnnuaireReducer;
