import { LOGOUT_USER } from '../Auth/Auth.constants';
import { REQUEST_SENT, REQUEST_SUCCEEDED, REQUEST_FAILED, CLEAR_API_MESSAGE } from '../../../setup/Api/Api.constants';

const initialState = {};

const ApiReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_SENT:
      return {
        ...state,
        [action.apiName]: {
          fetching: true,
          fetchingWith: action.apiParams,
          content: null
        }
      };

    case REQUEST_SUCCEEDED:
      return {
        ...state,
        [action.apiName]: {
          fetching: false,
          success: true,
          message: action.message,
          content: action.content
        }
      };

    case REQUEST_FAILED:
      return {
        ...state,
        [action.apiName]: {
          fetching: false,
          success: false,
          message: action.error,
          error: action.error
        }
      };

    case CLEAR_API_MESSAGE:
      return {
        ...state,
        [action.apiName]: {}
      };

    case LOGOUT_USER:
      return {
        ...initialState
      };

    default:
      return state;
  }
};

ApiReducer.hydrate = () => initialState;

export default ApiReducer;
