// every key in this config object has two values:
// - platform: The "___VALUE__" is a placeholder.
//             It will be replaced at the deployment according to the target platform
// - default:  The value to use if the platform value is still a placeholder.
//             For example in development
const config = {
  apiUrl: {
    platform: '___API_URL___',
    // default: 'https://sigess.gouv.ci/app/sigessApi/api'
    default: 'https://sigess-api.digimix.pro/sigessApi/api'
    // default: 'http://localhost:8080/sigessApi/api'
  }
};

export function getConfig(...keys) {
  let key;
  let value = config;

  while (returnKey()) {
    if (!value[key]) {
      throw new Error(`Unknown platform config key: ${key}`);
    }
    value = value[key];
  }

  if (value.platform.startsWith('___')) {
    return value.default;
  }

  return value.platform;

  function returnKey() {
    return (key = keys.shift());
  }
}
