import { useSelector } from 'react-redux';

import { Avatar } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const user = useSelector((state) => state.auth);

  return user.urlLogo ? (
    <MAvatar
      src={user.urlLogo}
      alt={user.nomAffichable}
      color={user.urlLogo ? 'default' : createAvatar(user.displayName).color}
      {...other}
    >
      <>{createAvatar(user.nomAffichable)}</>
    </MAvatar>
  ) : (
    <Avatar>
      <PersonIcon />
    </Avatar>
  );
}
