export const getToken = (state) => state.auth.token && state.auth.token.value;

export const getUser = (state) => state.auth;

export const getUserIdentifiant = (state) => state.auth.identifiant;

export const getUserNomAffichable = (state) => state.auth.nomAffichable;

export const getRefreshToken = (state) => state.auth.refreshToken;

export const isUserIsAdmin = (state) =>
  state.auth.roles
    ? state.auth.roles.includes('ROLE_DPPS') ||
      state.auth.roles.includes('ROLE_STRUCTURE') ||
      state.auth.roles.includes('ROLE_ADMIN')
    : false;
export const isUserIsSuperAdmin = (state) =>
  state.auth.roles ? state.auth.roles.includes('ROLE_DPPS') || state.auth.roles.includes('ROLE_ADMIN') : false;

export const isUserIsLivreur = (state) => (state.auth.roles ? state.auth.roles.includes('ROLE_LIVREUR') : false);

export const isUserIsInfographe = (state) => (state.auth.roles ? state.auth.roles.includes('ROLE_INFOGRAPHE') : false);

export const isServiceClient = (state) => (state.auth.roles ? state.auth.roles.includes('ROLE_SERVICE_CLIENT') : false);

export const isGestionCommande = (state) =>
  state.auth.roles ? state.auth.roles.includes('ROLE_GESTION_COMMANDE') : false;

export const isGestionLivraison = (state) =>
  state.auth.roles ? state.auth.roles.includes('ROLE_SERVICE_LIVRAISON') : false;

export const isUserIsResponsable = (state) =>
  state.auth.roles ? state.auth.roles.includes('ROLE_RESPONSABLE') : false;

export const isUserIsGestionnaire = (state) =>
  state.auth.roles ? state.auth.roles.includes('ROLE_GESTIONNAIRE') : false;

export const isUserDirty = (state) => state.auth.dirty;

export const isForceChangePassword = (state) => state.auth.forceChangePassword;

export const getTokenDate = (state) => state.auth.token && state.auth.token.expirationDate;

export const getCodification = (state, code) =>
  state.auth.codification && state.auth.codification[code] ? state.auth.codification[code] : undefined;

export function isTokenValid(state) {
  const date = getTokenDate(state);
  if (!date) {
    return false;
  }

  return new Date(date) - new Date() > 0;
}

export const getStructures = (state) => state.auth.structures;
export const getUtilisateurs = (state) => state.auth.utilisateurs;

export const getAutreStructure = (state) => state.auth.autreStructure;
