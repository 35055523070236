import { getConfig } from '../platform';

const baseApi = getConfig('apiUrl');

// eslint-disable-next-line
export default {
  'utilisateurs:lister': {
    url: `${baseApi}/utilisateur/lister`,
    method: 'GET',
    secure: true
  },
  'utilisateurs:creer': {
    url: `${baseApi}/utilisateur/creer`,
    method: 'POST',
    secure: true
  },
  'utilisateurs:modifier': {
    url: `${baseApi}/utilisateur/modifier`,
    method: 'POST',
    secure: true
  },
  'utilisateurs:debloquer': {
    url: (id) => `${baseApi}/utilisateur/structure/debloquer/${id}`,
    method: 'GET',
    secure: true
  },
  'utilisateurs:bloquer': {
    url: (id) => `${baseApi}/utilisateur/structure/bloquer/${id}`,
    method: 'GET',
    secure: true
  },
  'auth:login': {
    url: `${baseApi}/utilisateur/connexion`,
    method: 'POST'
  },
  'auth:refreshToken': {
    url: (refreshToken) => `${baseApi}/utilisateur/refreshToken?refreshToken=${refreshToken}`,
    method: 'GET'
  },
  'auth:mdpOublie': {
    url: (mail) => `${baseApi}/utilisateur/mdpOublie?identifiant=${mail}`,
    method: 'GET'
  },
  'auth:modifierMdp': {
    url: `${baseApi}/utilisateur/modifierMdp`,
    method: 'POST',
    secure: true
  },
  'dashboard:lire': {
    url: (annee) => `${baseApi}/dashboard/lire/${annee}`,
    method: 'GET',
    secure: true
  },
  'dashboard:lireMois': {
    url: `${baseApi}/dashboard/lireMois`,
    method: 'GET',
    secure: true
  },
  'dashboard:retardSaisie': {
    url: `${baseApi}/dashboard/retardSaisie`,
    method: 'GET',
    secure: true
  },
  'dashboard:ecartEffectif': {
    url: (param) => `${baseApi}/dashboard/ecartEffectif/${param.mois}/${param.annee}`,
    method: 'GET',
    secure: true
  },
  'codification:lister': {
    url: (code) => `${baseApi}/codifications/lister/${code}`,
    method: 'GET',
    secure: true
  },
  'documents:upload': {
    url: `${baseApi}/documents/upload`,
    method: 'POST',
    secure: true,
    fileUpload: true
  },
  'documents:lister': {
    url: `${baseApi}/documents/lister`,
    method: 'POST',
    secure: true
  },
  'documents:supprimer': {
    url: `${baseApi}/documents/supprimer`,
    method: 'POST',
    secure: true
  },
  'documents:supprimerAvecNom': {
    url: (fileName) => `${baseApi}/documents/supprimer/${fileName}`,
    method: 'DELETE',
    secure: true
  },
  'documents:download': {
    url: (fileName) => `${baseApi}/documents/download/${fileName}`,
    method: 'DELETE',
    secure: true
  },
  'utilisateur:inscrireStructure': {
    url: () => `${baseApi}/inscrire`,
    method: 'POST',
    secure: true
  },
  'utilisateurs:listerstructure': {
    url: () => `${baseApi}/structure/lister`,
    method: 'GET',
    secure: true
  },
  'annuaire:lire': {
    url: (annee) => `${baseApi}/annuaire/lire/${annee}`,
    method: 'GET',
    secure: true
  },
  'annuaire:generer': {
    url: (param) => `${baseApi}/annuaire/generer/${param.annee}/${param.preview}`,
    method: 'GET',
    secure: true
  },
  'annuaire:enregistrer': {
    url: () => `${baseApi}/annuaire/enregistrer`,
    method: 'POST',
    secure: true
  },
  'formulaire:enregistrer': {
    url: (param) => `${baseApi}/formulaire/enregistrer/${param.mois}/${param.annee}`,
    method: 'POST',
    secure: true
  },
  'formulaire:modifier': {
    url: () => `${baseApi}/formulaire/modifier`,
    method: 'PUT',
    secure: true
  },
  'formulaire:lire': {
    url: (numero) => `${baseApi}/formulaire/lire/${numero}`,
    method: 'GET',
    secure: true
  },
  'formulaire:lister': {
    url: (param) => `${baseApi}/formulaire/lister/${param.mois}/${param.annee}`,
    method: 'GET',
    secure: true
  },
  'formulaire:valider': {
    url: (numero) => `${baseApi}/formulaire/valider/${numero}`,
    method: 'GET',
    secure: true
  },
  'formulaire:approuver': {
    url: (numero) => `${baseApi}/formulaire/approuver/${numero}`,
    method: 'GET',
    secure: true
  },
  'formulaire:diffuser': {
    url: (numero) => `${baseApi}/formulaire/diffuser/${numero}`,
    method: 'GET',
    secure: true
  },
  'formulaire:soumettre': {
    url: (numero) => `${baseApi}/formulaire/soumettre/${numero}`,
    method: 'GET',
    secure: true
  },
  'formulaire:renvoyer': {
    url: () => `${baseApi}/formulaire/renvoyer`,
    method: 'POST',
    secure: true
  },
  'formulaire:changerEtat': {
    url: (param) => `${baseApi}/formulaire/changerEtat/${param.numero}/${param.etat}`,
    method: 'GET',
    secure: true
  },
  'formulaire:structureLister': {
    url: () => `${baseApi}/formulaire/structures/lister`,
    method: 'POST',
    secure: true
  },

  'mail:listerAdresses': {
    url: (structure) => `${baseApi}/mail/adresses/${structure}`,
    method: 'GET',
    secure: true
  },
  'mail:envoyer': {
    url: () => `${baseApi}/mail/envoyer`,
    method: 'POST',
    secure: true
  },
  'mail:listerEnvoyes': {
    url: () => `${baseApi}/mail/listerEnvoyes`,
    method: 'GET',
    secure: true
  },
  'mail:listerRecus': {
    url: () => `${baseApi}/mail/listerRecus`,
    method: 'GET',
    secure: true
  },
  'mail:marquerLu': {
    url: (id) => `${baseApi}/mail/lu/${id}`,
    method: 'GET',
    secure: true
  },
  'mail:marquerImportant': {
    url: (param) => `${baseApi}/mail/important/${param.id}/${param.important}`,
    method: 'GET',
    secure: true
  }
};
