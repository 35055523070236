import { REQUEST_FAILED, REQUEST_SENT, REQUEST_SUCCEEDED, CLEAR_API_MESSAGE, HTTP_ERROR } from './Api.constants';

export function requestSent(apiName, apiParams = []) {
  return {
    type: REQUEST_SENT,
    apiName,
    apiParams
  };
}

export function requestSucceeded(apiName, content, message) {
  return {
    type: REQUEST_SUCCEEDED,
    apiName,
    message,
    content
  };
}

export function requestFailed(apiName, code = false, label) {
  const error = code ? label : HTTP_ERROR;

  return {
    type: REQUEST_FAILED,
    apiName,
    error
  };
}

export function clearApiMessage(apiName) {
  return {
    type: CLEAR_API_MESSAGE,
    apiName
  };
}
