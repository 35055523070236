import { LOGOUT_USER } from '../Auth/Auth.constants';
import {
  LIRE_FORMULAIRE,
  CREER_FORMULAIRE,
  MODIFIER_FORMULAIRE,
  LISTER_FORMULAIRES,
  VALIDER_FORMULAIRES,
  APPROUVER_FORMULAIRES,
  DIFFUSER_FORMULAIRES,
  SOUMETTRE_FORMULAIRES,
  RENVOYER_FORMULAIRES,
  CHANGER_ETAT_FORMULAIRES,
  LISTER_FORMULAIRES_STRUCTURES,
  CHANGER_FORMULAIRE
} from './Saisies.constants';

const defaultState = { formulaireOuvert: {} };

function updateData(oldData, formulaire) {
  const newData = { ...oldData, ...{ [formulaire.numero]: formulaire } };
  return { data: newData };
}

const initialState = defaultState;

const SaisieReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREER_FORMULAIRE:
    case MODIFIER_FORMULAIRE:
      return {
        ...state,
        listFormulaires: undefined,
        formulaireOuvert: action.data
      };
    case SOUMETTRE_FORMULAIRES:
      return {
        ...state,
        listFormulaires: undefined
      };
    case VALIDER_FORMULAIRES:
    case APPROUVER_FORMULAIRES:
    case DIFFUSER_FORMULAIRES:
    case RENVOYER_FORMULAIRES:
    case CHANGER_ETAT_FORMULAIRES:
      return {
        ...state,
        listFormulaires: undefined,
        listFormulairesStructure: undefined
      };
    case LISTER_FORMULAIRES:
      return {
        ...state,
        listFormulaires: action.data
      };
    case LISTER_FORMULAIRES_STRUCTURES:
      return {
        ...state,
        listFormulairesStructure: action.data
      };
    case CHANGER_FORMULAIRE:
      return {
        ...state,
        formulaireOuvert: action.formulaire
      };
    case LIRE_FORMULAIRE:
      return {
        ...state,
        ...updateData(state.data, action.data)
      };
    case LOGOUT_USER:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

SaisieReducer.hydrate = () => initialState;

export default SaisieReducer;
