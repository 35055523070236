import PropTypes from 'prop-types';
import React from 'react';
import { Dialog, DialogContent, Box } from '@material-ui/core';
import LogoForm from './LogoForm';

const UploadLogoDialog = ({ open, handleClose }) => (
  <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
    <DialogContent dividers style={{ textAlign: 'center' }}>
      <Box sx={{ p: 3 }}>
        <LogoForm handleClose={handleClose} />
      </Box>
    </DialogContent>
  </Dialog>
);

UploadLogoDialog.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.any
};

export default UploadLogoDialog;
