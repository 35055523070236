import { LOGOUT_USER } from '../Auth/Auth.constants';
import {
  LISTER_ADRESSES,
  ENVOYER_MAIL,
  LISTER_MAILS_ENVOYES,
  LISTER_MAILS_RECUS,
  MARQUER_MAIL_LU,
  MARQUER_MAIL_IMPORTANT
} from './Mail.constants';

const initialState = {};

const MailReducer = (state = initialState, action) => {
  switch (action.type) {
    case ENVOYER_MAIL:
    case LISTER_MAILS_ENVOYES:
      return { ...state, envoyes: action.data };
    case LISTER_MAILS_RECUS:
    case MARQUER_MAIL_LU:
    case MARQUER_MAIL_IMPORTANT:
      return { ...state, recus: action.data };
    case LISTER_ADRESSES:
      return { ...state, adresses: action.data };
    case LOGOUT_USER:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

MailReducer.hydrate = () => initialState;

export default MailReducer;
