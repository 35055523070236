// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  contact: '/contact-us',
  page404: '/404',
  page500: '/500'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: path(ROOTS_DASHBOARD, '/'),
    app: path(ROOTS_DASHBOARD, '/app')
  },
  utilisateurs: path(ROOTS_DASHBOARD, '/utilisateurs'),
  structures: path(ROOTS_DASHBOARD, '/structures'),
  saisiesstructures: path(ROOTS_DASHBOARD, '/saisiesstructures'),
  messaisies: path(ROOTS_DASHBOARD, '/messaisies'),
  saisies: path(ROOTS_DASHBOARD, '/saisies'),
  annuaire: path(ROOTS_DASHBOARD, '/annuaire'),
  mail: path(ROOTS_DASHBOARD, '/mail'),
  chgmdp: path(ROOTS_DASHBOARD, '/chgmdp')
};

export const PATH_DOCS = 'https://digimix.pro/';
