import { Icon } from '@iconify/react';
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'menu',
    items: [
      {
        title: 'tableau de bord',
        path: PATH_DASHBOARD.general.app,
        icon: <Icon icon="ant-design:fund-projection-screen-outlined" width="2048" height="2048" />
      },
      {
        title: 'saisies de données',
        path: PATH_DASHBOARD.saisies,
        icon: <Icon icon="mdi:file-document-multiple" width="2048" height="2048" />
      },
      {
        title: 'annuaire',
        path: PATH_DASHBOARD.annuaire,
        icon: <Icon icon="wpf:statistics" width="2048" height="2048" />,
        superAdmin: true
      },
      // {
      //   title: 'livraisons',
      //   path: PATH_DASHBOARD.livraisons,
      //   icon: <Icon icon="mdi:motorbike" width="2048" height="2048" />,
      //   livreur: true
      // },
      // {
      //   title: 'infographie',
      //   path: PATH_DASHBOARD.infographie,
      //   icon: <Icon icon="el:print" width="2048" height="2048" />,
      //   infographie: true
      // },
      // {
      //   title: 'articles',
      //   path: PATH_DASHBOARD.articles.root,
      //   icon: <Icon icon="ooui:articles-ltr" width="2048" height="2048" />,
      //   gestionnaire: true
      // },
      {
        title: 'utilisateurs',
        path: PATH_DASHBOARD.utilisateurs,
        icon: <Icon icon="bxs:user-pin" width="2048" height="2048" />,
        admin: true
      },
      {
        title: 'Structures',
        path: PATH_DASHBOARD.structures,
        icon: <Icon icon="mdi:building" width="2048" height="2048" />,
        superAdmin: true
      },
      {
        title: 'Boite mail',
        path: PATH_DASHBOARD.mail,
        icon: <Icon icon="material-symbols:outgoing-mail" width="2048" height="2048" />
      },
      {
        title: 'Changer mot de passe',
        path: PATH_DASHBOARD.chgmdp,
        icon: <Icon icon="mdi:password" width="2048" height="2048" />
      }
      // {
      //   title: 'Catégories',
      //   path: PATH_DASHBOARD.gestioncategories,
      //   icon: <Icon icon="carbon:list-dropdown" width="2048" height="2048" />,
      //   admin: true
      // },
      // {
      //   title: 'Rapport',
      //   path: PATH_DASHBOARD.rapport,
      //   icon: <Icon icon="bi:graph-up-arrow" width="2048" height="2048" />,
      //   admin: true
      // }
    ]
  }
];

export default sidebarConfig;
